@import "./fonts/base.css";

body {
  margin: 0;
  padding: 0;
  font-family: "GothamRounded", "Roboto","Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #363b3f;
}

::-moz-selection {
  background: #00a8e2;
  color: #ffffff;
  text-shadow: none;
}
::selection {
  background: #00a8e2;
  color: #ffffff;
  text-shadow: none;
}
/*
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
