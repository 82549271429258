@font-face {
  font-family: 'GothamRounded';
  src: url('./gotham-rounded/GothamRounded-Medium.eot'); /* IE9 Compat Modes */
  src: url('./gotham-rounded/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./gotham-rounded/GothamRounded-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('./gotham-rounded/GothamRounded-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('./gotham-rounded/GothamRounded-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./gotham-rounded/GothamRounded-Medium.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('./gotham-rounded/GothamRounded-Book.eot');
  /* IE9 Compat Modes */
  src: url('./gotham-rounded/GothamRounded-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./gotham-rounded/GothamRounded-Book.woff') format('woff'), /* Pretty Modern Browsers */
  url('./gotham-rounded/GothamRounded-Book.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./gotham-rounded/GothamRounded-Book.svg#svgFontName') format('svg');
  /* Legacy iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('./gotham-rounded/GothamRounded-Light.eot');
  /* IE9 Compat Modes */
  src: url('./gotham-rounded/GothamRounded-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./gotham-rounded/GothamRounded-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('./gotham-rounded/GothamRounded-Light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./gotham-rounded/GothamRounded-Light.svg#svgFontName') format('svg');
  /* Legacy iOS */
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'GothamRounded';
  src: url('./gotham-rounded/GothamRounded-Bold.eot');
  /* IE9 Compat Modes */
  src: url('./gotham-rounded/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./gotham-rounded/GothamRounded-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('./gotham-rounded/GothamRounded-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./gotham-rounded/GothamRounded-Bold.svg#svgFontName') format('svg');
  /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
